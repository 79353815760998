import {Component} from "react"
import {connect} from "react-redux"
import PropTypes from "prop-types"

export class Poller extends Component {
    componentDidMount() {
        this.managePoller()
    }

    dispatchAction () {
        this.props.dispatch(this.props.actionBuilder(this.props.state))
    }

    get enabled() {
        return this.props.selectEnabled(this.props.state)
    }

    managePoller() {
        if (this.enabled) {
            if (this.pollerHandle === undefined) {
                this.dispatchAction()
                this.pollerHandle = setInterval(
                    () => {
                        this.dispatchAction()
                    },
                    10000,
                )
            }
        } else {
            if (this.pollerHandle !== undefined) {
                clearInterval(this.pollerHandle)
                delete this.pollerHandle
            }
        }
    }

    componentDidUpdate() {
        this.managePoller()
    }

    render() {
        return null
    }
}

Poller.propTypes = {
    actionBuilder: PropTypes.func.isRequired,
    state: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    selectEnabled: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
    return {
        state: state
    }
}

export default connect(mapStateToProps)(Poller)

