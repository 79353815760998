import React from "react"
import {BrowserRouter, Link} from "react-router-dom"
import "./Menu.css"
import {getConfig} from "./config"

const MenuItems = ({appID, user}) => {
    const config = getConfig(user)

    return (
        <div className="ui borderless menu">
            <div className="item">
                <h4 className="header dashboard-title"> ISV Dashboard</h4>
            </div>
            {
                config["items"].map((itemConfig) => (itemConfig.id === appID) ?
                    <Link key={itemConfig.id} to={itemConfig.path || "/"} className="active item">{itemConfig.title}</Link>
                    :
                    <a key={itemConfig.id} href={itemConfig.ref} className="item">{itemConfig.title}</a>
                )
            }
            <div className="right menu">
                <div className="item">
                    <div className="ui compact menu">
                        <div className="ui simple dropdown item">
                            <i className="user alternate icon"/>
                            {user.name}
                            <i className="dropdown icon"/>
                            <div className="menu">
                                <a href={config.profile.ref} className="item">
                                    Profile
                                </a>
                                <a href={config.logout.ref} referrerPolicy={"no-referrer-when-downgrade"} className="item">
                                    Logout
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const MainMenu = (props) => {
    return (
        <BrowserRouter forceRefresh={true}>
            <div className="portal-menu container fluid">
                <MenuItems {...props}/>
                {props.children}
            </div>
        </BrowserRouter>
    )
}

export default MainMenu
