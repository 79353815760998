import React, {Component} from "react"

class TileIcon extends Component {

    constructor(props) {
        super(props)
        this.state = { visible: false }
    }

    render() {
        const slug = this.props.slug
        const releaseid = this.props.releaseid || "latest"
        return (
            <img
                {...this.props}
                alt="icon"
                src={"/api/tiles/" + slug + "/releases/" + releaseid + "/icon"}
                onLoad={() => { this.setState({visible: true}) }}
                style={{ ...this.props.style, display: this.state.visible ? "inline-block" : "none"}}
            />
        )
    }
}

export default TileIcon
