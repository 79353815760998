import React, {Component} from "react"
import {Menu} from "semantic-ui-react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import {acl} from "../selectors/permissions"

const MenuItem = (props) => {
    const path_name = props.name.toLowerCase()
    if (!props.acl.includes(path_name)) {
        return null
    }

    const path = "/" + path_name
    const active = props.location.startsWith(path)
    return (
        <Menu.Item active={active} as="a" href={`/${path_name}`}>
            {props.name}
        </Menu.Item>
    )
}

export class MainMenu extends Component {

    render() {
        return (
            <Menu borderless inverted className="grey" >
                <MenuItem name="Tiles" {...this.props}/>
                <MenuItem name="Jobs" {...this.props}/>
                <MenuItem name="Scan" {...this.props}/>
            </Menu>
        )
    }
}

MainMenu.propTypes = {
    acl: PropTypes.arrayOf(PropTypes.oneOf(["tiles", "jobs", "scan"])),
    history: PropTypes.object.isRequired,
    defaultRoute: PropTypes.string.isRequired
}

const mapStateToProps = (state, ownProps) => {
    let location = ownProps.history.location.pathname
    if (location === "/") location = ownProps.defaultRoute
    return {
        acl: acl(state),
        location: location
    }
}

export default connect(mapStateToProps)(MainMenu)
