import {handleActions} from "redux-actions"
import * as polling from "./shared/polling"

const matrix = handleActions(
    {
        MATRIX_RECEIVED: (previousState, action) => {
            if (action.error) {
                return {
                    ...previousState,
                    error: action.payload.toString()
                }
            }
            if (action.payload.cells) {
                var rows = previousState.rows
                for (var i = 0; i < action.payload.cells.length; i++) {
                    var cell = action.payload.cells[i]
                    var release_id = cell.release_id
                    var target_name = cell.target_name
                    for (var r = 0; r < rows.length; r++) {
                        var row = rows[r]
                        if (row.release_id.value === release_id) break
                    }
                    if (r >= rows.length) continue
                    var affected = row[target_name]
                    if (!affected) continue
                    rows[r][target_name] = cell
                }
                return {
                    ...previousState,
                    last_updated: action.payload.timestamp || previousState.last_updated,
                    rows: rows,
                }
            }

            return {
                ...previousState,
                ...action.payload,
                loading: false
            }
        },
        MATRIX_POLLING_STARTED: polling.start,
        MATRIX_POLLING_STOPPED: polling.stop,
        MATRIX_LOADING: (previousState) => {
            return {
                ...previousState,
                rows: [],
                columns: [],
                last_updated: null,
                loading: true
            }
        },
        MATRIX_REFRESH: (previousState) => {
            return {
                ...previousState,
                last_updated: null
            }
        }
    },
    {
        activePollers: [],
        rows: [],
        columns: [],
        last_updated: null,
        loading: true
    }
)

export default matrix