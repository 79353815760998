import React, {Component, Fragment} from "react"
import {connect} from "react-redux"
import {withRouter} from "react-router"
import queryString from "query-string"
import {getJob, receiveJob, retryJob} from "../actions/jobs"
import {Button, Loader} from "semantic-ui-react"
import DisplayDate from "../components/DisplayDate"
import SegmentTitle from "../components/SegmentTitle"
import StatusLabel from "../components/StatusLabel"
import JobLogs from "../containers/JobLogs"

const JobRetryButton = ({job, onClick}) => (
    <Button className="retryButton" size="tiny" primary onClick={() => onClick(job.slug, job.release_id, job.target_name)}>Retry</Button>
)

const JobDetails = ({jobId, job, prefix, isMostRecent, isAdmin, onRetry}) => (
    <Fragment>
        <SegmentTitle title={(prefix || "") + " job for target " + job.target_name} />
        { isMostRecent && isAdmin && <JobRetryButton job={job} onClick={onRetry}/> }
        <div><div className="detail-label">job id:</div>{job.id}</div>
        <div><div className="detail-label">status:</div>{job.status}</div>
        <div><div className="detail-label">last update:</div><DisplayDate date={job.date}/></div>
        <div><div className="detail-label">slug:</div>{job.slug}</div>
        <div><div className="detail-label">release id:</div>{job.release_id}</div>
        { job.summary &&
            <div style={{paddingTop: "20px", whiteSpace: "nowrap"}}>
                <div className="detail-label">summary:</div>
                <div style={{display: "inline-block", whiteSpace: "pre-wrap", verticalAlign: "top", overflowX: "hidden", width: "85%" }}>{job.summary}</div>
            </div>
        }
        <JobLogs jobId={jobId} />
    </Fragment>
)

class JobSummary extends Component {

    componentDidMount() {
        this.props.getJob(this.props.jobId)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.jobId !== this.props.jobId) {
            this.props.getJob(nextProps.jobId)
        }
    }

    render() {
        const prefix = this.props.isMostRecent ? "most recent" : "prior"
        const job = this.props.job
        return (
            <Fragment>
                {job && <StatusLabel status={job.status} ribbon />}
                {!job && <Loader indeterminate active>Loading Job Details</Loader>}
                {job && <JobDetails {...this.props} job={job} prefix={prefix} isMostRecent={this.props.isMostRecent} isAdmin={this.props.isAdmin} onRetry={this.props.retryJob} />}
            </Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const queryParams = queryString.parse(ownProps.history.location.search)
    const jobId = parseInt(queryParams["job"]) || ownProps.jobId
    const history = state.jobs.list
    return ({
        job: state.jobs.job,
        jobId: jobId,
        history: history
    })
}

const mapActionsToProps = {
    receiveJob,
    getJob,
    retryJob
}

export default withRouter(connect(mapStateToProps, mapActionsToProps)(JobSummary))
