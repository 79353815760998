import React from "react"
import {Label} from "semantic-ui-react"
import * as FontAwesome from "react-icons/fa"

export const ScanStateIcon = (props) => {
    switch (props.level) {
        case "WARNING": return <FontAwesome.FaExclamationTriangle {...props} color="#e0ad4e" />
        case "ERROR":   return <FontAwesome.FaExclamationCircle {...props} color="#e00000" />
        case "INFO":    return <FontAwesome.FaInfoCircle {...props} color="blue" />
        default:        return ""
    }
}

export const scanStateColor = (level, is_cleared=false) => {
    if (is_cleared) return "grey"
    switch (level) {
        case "ERROR":   return "red"
        case "WARNING": return "yellow"
        case "INFO":    return "blue"
        default:        return "grey"
    }
}

export const scanStateText = (level) => {
    switch (level) {
        case "ERROR":   return "Critical"
        case "WARNING": return "Warning"
        case "INFO":    return "Info"
        default:        return "Unknown"
    }
}

export const ScanStateLabel = (props) => (
    <Label color={scanStateColor(props.level, props.is_cleared)} {...props}>{scanStateText(props.level)}</Label>
)

export default ScanStateLabel
