import {createAction} from "redux-actions"
import * as HTTPStatus from "http-status-codes"
import {FetchOptions} from "../client-common/actions/common"

export const getMatrix = (path, since) => {
    return dispatch => {
        if (!since) dispatch(startLoadingMatrix())
        let url = "/api/matrix"
        if (path && path.startsWith("/")) path = path.slice(1)
        if (path) url += "/" + path.split("/").slice(0,2).join("/") // Ignore :tab
        if (since) url += "?since=" + since
        return fetch(url, FetchOptions).then(response => {
            if (response.status === HTTPStatus.OK) {
                return response.json()
            }
            return Promise.reject(Error(`HTTP Status Code: ${response.status}`))
        }).then(result => {
            dispatch(receiveMatrix(result))
        }).catch(error =>{
            dispatch(receiveMatrix(error))
        })
    }
}

export const toggleActive = (slug, release_id) => {
    return dispatch => {
        const url = "/api/matrix/" + slug + "/" + release_id + "/active"
        return fetch(url, {
            ...FetchOptions,
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({})}).then(response => {
            if (response.status === HTTPStatus.OK) {
                return response.json()
            }
            return Promise.reject(Error(`HTTP Status Code: ${response.status}`))
        }).then(result => {
            dispatch(receiveMatrix({
                cells: [{
                    release_id: release_id,
                    target_name: "active",
                    status: result.active
                }]
            }))
        }).catch(error =>{
            dispatch(receiveMatrix(error))
        })
    }
}

export const startLoadingMatrix = createAction("MATRIX_LOADING")
export const startMatrixPolling = createAction("MATRIX_POLLING_STARTED")
export const stopMatrixPolling = createAction("MATRIX_POLLING_STOPPED")
export const receiveMatrix = createAction("MATRIX_RECEIVED")
export const refreshMatrix = createAction("MATRIX_REFRESH")
