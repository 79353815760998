import {handleActions} from "redux-actions"

const tiles = handleActions(
    {
        TILE_RELEASE_RECEIVED: (previousState, action) => {
            if (action.error) {
                return {
                    ...previousState,
                    error: action.payload.toString()
                }
            }

            return {
                ...previousState,
                tileRelease: action.payload,
            }
        },
        TILE_METADATA_RECEIVED: (previousState, action) => {
            if (action.error) {
                return {
                    ...previousState,
                    error: action.payload.toString()
                }
            }

            return {
                ...previousState,
                tileMetadata: action.payload && action.payload.toString(),
            }
        },
        SCAN_RESULTS_RECEIVED: (previousState, action) => {
            if (action.error) {
                return {
                    ...previousState,
                    error: action.payload.toString()
                }
            }

            return {
                ...previousState,
                scanResults: Object.assign({}, action.payload, {
                    hits: action.payload && action.payload.hits && action.payload.hits.reduce((hits, hit) => {
                        hits[hit.rule.id] = Object.assign({}, hit, {
                            matches: hit.matches && hit.matches.reduce((matches, match) => {
                                if (!(match.file in matches)) matches[match.file] = {
                                    file: match.file,
                                    matches: []
                                }
                                matches[match.file].matches.push(match)
                                return matches
                            }, {})
                        })
                        return hits
                    }, {})
                })
            }
        },
        SCAN_HIT_RECEIVED: (previousState, action) => {
            if (action.error) {
                return {
                    ...previousState,
                    error: action.payload.toString()
                }
            }

            return {
                ...previousState,
                scanResults: Object.assign({}, previousState.scanResults, {
                    hits: Object.assign({}, previousState.scanResults.hits, {
                        [action.payload.scan_rule_id]: Object.assign({}, previousState.scanResults.hits[action.payload.scan_rule_id], {
                            scanner_hit: action.payload
                        })
                    })
                })
            }
        },
        TILE_RECEIVED: (previousState, action) => {
            if (action.error) {
                return {
                    ...previousState,
                    error: action.payload.toString()
                }
            }

            return {
                ...previousState,
                tile: action.payload,
            }
        },
        TILES_RECEIVED: (previousState, action) => {
            if (action.error) {
                return {
                    ...previousState,
                    error: action.payload.toString()
                }
            }

            return {
                ...previousState,
                list: action.payload,
            }
        },
        TILES_FILTER: (previousState, {payload}) => {
            return {...previousState, filter: payload}
        },
    },
    {
        list: null,
        tile: null,
        tileRelease: null,
        tileMetadata: null,
        scanResults: null,
        filter: ""
    }
)

export default tiles