import { createStore, applyMiddleware } from "redux"
import { createLogger } from "redux-logger"
import reducer from "./reducers/reducer"
import thunkMiddleware from "redux-thunk"
import config from "./config"
import { routerMiddleware } from "connected-react-router"
import { createBrowserHistory } from "history"

const makeStore = () => {
    let history = createBrowserHistory()

    const middleware = [thunkMiddleware, routerMiddleware(history)]

    if (config.logging.enabled) {
        middleware.push(createLogger())
    }

    const store = createStore(
        reducer(history),
        applyMiddleware(...middleware)
    )

    return {store, history}
}

export default makeStore

