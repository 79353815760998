const DisplayAge = ({seconds}) => {
    if (seconds === 1) {
        return "1 second"
    }
    if (seconds < 60) {
        return seconds + " seconds"
    }
    var minutes = Math.floor(seconds / 60)
    if (minutes === 1) {
        return "1 minute"
    }
    if (minutes < 60) {
        return minutes + " minutes"
    }
    var hours = Math.floor(minutes / 60)
    if (hours === 1) {
        return "1 hour"
    }
    if (hours < 24) {
        return hours + " hours"
    }
    var days = Math.floor(hours / 24)
    if (days === 1) {
        return "1 day"
    }
    if (days < 7) {
        return days + " days"
    }
    var weeks = Math.floor(days / 7)
    if (weeks === 1) {
        return "1 week"
    }
    if (weeks < 52) {
        return weeks + " weeks"
    }
    var years = Math.floor(weeks / 52)
    if (years === 1) {
        return "1 year"
    }
    return years + " years"
}

export default DisplayAge
