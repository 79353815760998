import React, {Component, Fragment} from "react"
import {connect} from "react-redux"
import {getDependencies, receiveDependencies} from "../actions/jobs"
import StatusLabel, {StatusIcon, statusText} from "../components/StatusLabel"
import SegmentTitle from "../components/SegmentTitle"

const CellDependencies = (props) => {
    if (!props.dependencies) return null
    return (
        <Fragment>
            {
                props.dependencies.map((dependency, index) => (
                    <CellDependency key={index} dependency={dependency} />
                ))
            }
        </Fragment>
    )
}

const CellDependency = (props) => {
    if (props.dependency.or) {
        return (
            <div className="dependency-subgroup">
                <div className="detail-label">Any of:</div>
                <CellDependencies dependencies={props.dependency.or}/>
            </div>
        )
    }

    return (
        <div>
            <div className="detail-label"><StatusIcon status={props.dependency.status} size="mini" /></div>
            {props.dependency.name}
        </div>
    )
}

class CellStatus extends Component {

    componentDidMount() {
        if (this.props.cell.status === "blocked") {
            this.props.getDependencies(this.props.slug, this.props.releaseId, this.props.target)
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.slug !== this.props.slug || nextProps.releaseId !== this.props.releaseId || nextProps.target !== this.props.target) {
            this.props.getDependencies(nextProps.slug, nextProps.releaseId, nextProps.target)
        }
    }

    render() {
        const cell = this.props.cell
        if (!cell) return null
        var title = "job for target " + cell.target_name + " is " + statusText(cell.status)
        if (cell.status === "blocked") title += " waiting for"
        return (
            <Fragment>
                <StatusLabel status={cell.status} ribbon />
                <SegmentTitle title={title} />
                { cell.status === "blocked" && <CellDependencies {...this.props} /> }
            </Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return ({
        cell: ownProps.cell,
        releaseId: ownProps.cell.release_id,
        target: ownProps.cell.target_name,
        dependencies: state.jobs.dependencies
    })
}

const mapActionsToProps = {
    receiveDependencies,
    getDependencies
}

export default connect(mapStateToProps, mapActionsToProps)(CellStatus)
