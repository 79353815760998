import React, {Component, Fragment} from "react"
import {connect} from "react-redux"
import {getScanResults, receiveScanResults, updateScanHit} from "../actions/tiles"
import {Segment, Label, Checkbox, Accordion, Icon, Divider} from "semantic-ui-react"
import ScanStateLabel from "../components/ScanStateLabel"

class ScanMatch extends Component {

    constructor(props) {
        super(props)
        this.state = { active: false }
        this.handleClick = this.handleClick.bind(this)
    }
    
    handleClick() {
        this.setState({active: !this.state.active })
    }

    render() {
        const active = this.state.active
        const match = this.props.match
        return (
            <Fragment>
                <Accordion.Title active={active} onClick={ this.handleClick }>
                    <Icon name='dropdown' />
                    <span style={{ fontWeight: "bold" }}>{ match.file }</span>
                </Accordion.Title>
                <Accordion.Content active={active} style={{ paddingLeft: "24px", whiteSpace: "pre-wrap", wordWrap: "break-word", fontFamily: "monospace", fontSize: ".8em" }}>
                    { match.matches.map((match, key) => (
                        <div key={key}>
                            { match.lineno && <div style={{ display: "inline-block", width: "50px", textAlign: "right", marginRight: "12px" }}>{match.lineno}</div> }
                            { match.line }
                        </div>
                    ))}
                </Accordion.Content>
            </Fragment>
        )
    }
}

const ScanHit = ({hit, isAdmin, onChange}) => (
    <Segment raised>
        <div style={{ whiteSpace: "nowrap" }}>
            <ScanStateLabel ribbon level={hit.rule.level} is_cleared={hit.scanner_hit.is_cleared}/>
            <h5 style={{ whiteSpace: "normal", verticalAlign: "top", width: "80%" }}>{hit.rule.message}</h5>
            { isAdmin && hit.rule.level !== "INFO" &&
                <Checkbox
                    toggle
                    style={{ float: "right" }}
                    label="Cleared"
                    checked={hit.scanner_hit.is_cleared === 1}
                    onChange={() => onChange({ is_cleared: hit.scanner_hit.is_cleared ? 0 : 1})}
                />
            }
            { hit.matches &&
                <Fragment>
                    <Divider/>
                    <Accordion fluid>
                        { Object.values(hit.matches).map((match, key) => ( <ScanMatch match={match} key={key} /> )) }
                    </Accordion>
                </Fragment>
            }
        </div>
    </Segment>
)

class ScanResults extends Component {
    componentDidMount() {
        this.props.getScanResults(this.props.slug, this.props.releaseId)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.slug !== this.props.slug || nextProps.releaseId !== this.props.releaseId) {
            this.props.getScanResults(nextProps.slug, nextProps.releaseId)
        }
    }

    render() {
        const scanResults = this.props.scanResults
        if (!scanResults) return null
        const hits = scanResults.hits && Object.values(scanResults.hits)
        if (!hits) return null
        if (hits.length > 0) return (
            <Fragment>
                { hits.map((hit, key) => (
                    <ScanHit
                        key={key}
                        hit={hit}
                        isAdmin={this.props.isAdmin}
                        onChange={(update) => this.props.updateScanHit(hit.scanner_hit, update)}
                    />
                ))}
            </Fragment>
        )
        else return (
            <Segment raised>
                <Label ribbon color="green">Clean</Label>
                Scanner found no issues
            </Segment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return ({
        slug: ownProps.slug,
        releaseId: ownProps.releaseId,
        isAdmin: ownProps.isAdmin,
        scanResults: state.tiles.scanResults
    })
}

const mapActionsToProps = {
    receiveScanResults,
    getScanResults,
    updateScanHit
}

export default connect(mapStateToProps, mapActionsToProps)(ScanResults)
