import {createAction} from "redux-actions"
import {receiveMatrix, refreshMatrix} from "../actions/matrix"
import * as HTTPStatus from "http-status-codes"
import {FetchOptions} from "../client-common/actions/common"

export const getJobs = (releaseId = null, targetName = null) => {
    return dispatch => {
        dispatch(startLoadingJobs())
        let url = "/api/jobs?limit=100"
        if (releaseId) url += "&release_id=" + releaseId
        if (targetName) url += "&target_name=" + targetName
        return fetch(url, FetchOptions).then(response => {
            if (response.status === HTTPStatus.OK) {
                return response.json()
            }
            return Promise.reject(Error(`HTTP Status Code: ${response.status}`))
        }).then(result => {
            dispatch(receiveJobs(result.jobs))
        }).catch(error => {
            dispatch(receiveJobs(error))
        })
    }
}

export const getJob = (jobId) => {
    return dispatch => {
        dispatch(receiveJob(null)) // Erase prior result
        const url = "/api/jobs/" + jobId
        return fetch(url, FetchOptions).then(response => {
            if (response.status === HTTPStatus.OK) {
                return response.json()
            }
            return Promise.reject(Error(`HTTP Status Code: ${response.status}`))
        }).then(result => {
            dispatch(receiveJob(result))
        }).catch(error => {
            dispatch(receiveJob(error))
        })
    }
}

export const retryJob = (slug, releaseId, targetName) => {
    return dispatch => {
        dispatch(receiveJob(null))
        dispatch(receiveMatrix(
            {
                cells: [
                    {
                        release_id: releaseId,
                        target_name: targetName,
                        status: "untested",
                        latest_job: null
                    }
                ]
            }
        ))

        const url = "/api/matrix/" + slug + "/" + releaseId + "/" + targetName
        return fetch(url, {...FetchOptions, method: "DELETE"}).then(response => {
            if (response.status === HTTPStatus.OK) {
                return response.json()
            }
            return Promise.reject(Error(`HTTP Status Code: ${response.status}`))
        }).then((/* result */) => {
            // No-op
        }).catch((/* error */) => {
            dispatch(refreshMatrix()) // Force update
        })
    }
}

export const getDependencies = (slug, releaseId, target) => {
    return dispatch => {
        dispatch(receiveDependencies([])) // Erase prior result
        const url = "/api/matrix/" + slug + "/" + releaseId + "/" + target + "/dependencies"
        return fetch(url, FetchOptions).then(response => {
            if (response.status === HTTPStatus.OK) {
                return response.json()
            }
            return Promise.reject(Error(`HTTP Status Code: ${response.status}`))
        }).then(result => {
            dispatch(receiveDependencies(result))
        }).catch(error => {
            dispatch(receiveDependencies(error))
        })
    }
}

export const getLogs = (jobId) => {
    return dispatch => {
        dispatch(receiveLogs(null)) // Erase prior result
        const url = "/api/jobs/" + jobId + "/logs"
        return fetch(url, FetchOptions).then(response => {
            if (response.status === HTTPStatus.OK) {
                return response.json()
            }
            if (response.status === HTTPStatus.NOT_FOUND) {
                return null
            }
            return Promise.reject(Error(`HTTP Status Code: ${response.status}`))
        }).then(result => {
            dispatch(receiveLogs(result))
        }).catch(error => {
            dispatch(receiveLogs(error))
        })
    }
}

export const startLoadingJobs = createAction("JOBS_LOADING")
export const receiveJobs = createAction("JOBS_RECEIVED")
export const receiveJob = createAction("JOB_RECEIVED")
export const receiveLogs = createAction("LOGS_RECEIVED")
export const receiveDependencies = createAction("JOB_DEPENDENCIES_RECEIVED")
