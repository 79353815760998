import {createAction} from "redux-actions"
import * as HTTPStatus from "http-status-codes"
import {FetchOptions} from "./common"

export const authorize = () => dispatch => {
    dispatch(requestAuthorization())

    return fetch("/api/user", FetchOptions).then(async response => {
        if (response.status === HTTPStatus.UNAUTHORIZED) {
            const user = await response.json()
            const link = user.links.find((link) => link.rel === "login")
            return Promise.resolve({
                authURI: link.href,
                authorization: "unauthorized"
            })
        }

        if (response.status === HTTPStatus.OK) {
            const user = await response.json()
            return Promise.resolve({
                authorization: "authorized",
                user
            })
        }

        return Promise.reject(Error(`HTTP Status Code: ${response.status}`))

    }).then(result => {
        dispatch(receiveAuthorization(result))
    }).catch(error => {
        dispatch(receiveAuthorization(error))
    })
}


export const requestAuthorization = createAction("AUTHORIZATION_REQUEST")
export const receiveAuthorization = createAction("AUTHORIZATION_RECEIVE")

