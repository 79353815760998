import React, {Fragment} from "react"
import "./Footer.css"
import {Link} from "react-router-dom"

const Footer = ({links}) => {
    const numLinks = links ? Object.keys(links).length : 0
    const style = {
        height: `${2 + numLinks}em`
    }

    return (
        <Fragment>
            <div className="footer-padding" style={style}/>
            <div className="footer" style={style}>
                {numLinks > 0 && Object.keys(links).map(key =>
                    <p key={key}>
                        <Link to={links[key]}>{key}</Link>
                    </p>
                )}
                <p key="copyright">
                    Copyright &copy; {(new Date().getFullYear())} VMware, Inc. or its affiliates
                </p>
            </div>
        </Fragment>
    )
}

export default Footer
