import {handleActions} from "redux-actions"

const scan = handleActions(
    {
        SCAN_RULES_RECEIVED: (previousState, action) => {
            if (action.error) {
                return {
                    ...previousState,
                    error: action.payload.toString()
                }
            }

            return {
                ...previousState,
                list: action.payload && action.payload.rules
            }
        },
        SCAN_RULE_RECEIVED: (previousState, action) => {
            if (action.error) {
                return {
                    ...previousState,
                    error: action.payload.toString()
                }
            }

            return {
                ...previousState,
                rule: action.payload
            }
        }
    },
    {
        rule: null,
        list: null
    }
)

export default scan