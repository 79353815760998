import React, {Component, Fragment} from "react"
import {connect} from "react-redux"
import Matrix from "../containers/Matrix"
import SearchBar from "../components/SearchBar"
import {filterTiles} from "../actions/tiles"

export class Tiles extends Component {
    render() {
        return (
            <Fragment>
                { this.props.searchBarVisible && <SearchBar action={this.props.filterTiles} value={this.props.filter}/> }
                <Matrix/>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    filter: state.tiles.filter,
    searchBarVisible: state.user.is_admin
})

const mapActionsToProps = {
    filterTiles,
}

export default connect(mapStateToProps, mapActionsToProps)(Tiles)
