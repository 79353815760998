import React, {Component} from "react"
import {connect} from "react-redux"
import {Container} from "semantic-ui-react"
import {getTiles, receiveTiles} from "../actions/tiles"
import {Link} from "react-router-dom"
import TileIcon from "../components/TileIcon"

export class TileGrid extends Component {
    componentDidMount() {
        this.props.getTiles()
    }

    render() {
        const tiles = this.props.tiles
        if (!tiles) return null
        return (
            <Container fluid className="tile-grid">
                { tiles.map((tile, key) => (
                    <Link key={key} to={"/browse/" + tile.slug}>
                        <div className="tile">
                            <div className="icon">
                                <TileIcon slug={tile.slug}/>
                            </div>
                            <div className="name">{tile.name}</div>
                            <div className="version">{tile.latest_version}</div>
                        </div>
                    </Link>
                ))}
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        tiles: state.tiles.list,
    })
}

const mapActionsToProps = {
    receiveTiles,
    getTiles
}

export default connect(mapStateToProps, mapActionsToProps)(TileGrid)
