import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"

export class Tag extends Component {
    render() {
        return (<Fragment>{this.props.children}</Fragment>)
    }
}

Tag.propTypes = {
    children: PropTypes.node
}

export class PropertyRouter extends Component {
    render() {
        const elements = []

        this.props.children.forEach((tag) => {
            if (tag.type.filter(this.props)) {
                elements.push(tag)
            }
        })

        return <Fragment>{elements}</Fragment>
    }
}

PropertyRouter.propTypes = {
    children: PropTypes.array
}
