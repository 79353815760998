import {createAction} from "redux-actions"
import * as HTTPStatus from "http-status-codes"
import {FetchOptions} from "../client-common/actions/common"

export const getScanRules = () => {
    return dispatch => {
        dispatch(receiveScanRules(null))
        return fetch("/api/scan-rules", FetchOptions).then(response => {
            if (response.status === HTTPStatus.OK) {
                return response.json()
            }
            return Promise.reject(Error(`HTTP Status Code: ${response.status}`))
        }).then(result => {
            dispatch(receiveScanRules(result))
        }).catch(error =>{
            dispatch(receiveScanRules(error))
        })
    }
}

export const getScanRule = (ruleId) => {
    return dispatch => {
        dispatch(receiveScanRule(null)) // Erase prior result
        return fetch("/api/scan-rules/" + ruleId, FetchOptions).then(response => {
            if (response.status === HTTPStatus.OK) {
                return response.json()
            }
            return Promise.reject(Error(`HTTP Status Code: ${response.status}`))
        }).then(result => {
            dispatch(receiveScanRule(result))
        }).catch(error =>{
            dispatch(receiveScanRule(error))
        })
    }
}

export const receiveScanRule = createAction("SCAN_RULE_RECEIVED")
export const receiveScanRules = createAction("SCAN_RULES_RECEIVED")
