import React from "react"
import * as FontAwesome from "react-icons/fa"

export const ActiveIcon = ({status, is_latest, onClick}) => {
    switch (status) {
        case "auto":
            if (is_latest) return <FontAwesome.FaPlay color="#eee" style={{cursor: "pointer"}} onClick={onClick} />
            else return <FontAwesome.FaPause color="#eee" style={{cursor: "pointer"}} onClick={onClick} />
        case "stop": return <FontAwesome.FaStop color="#e00000" style={{cursor: "pointer"}} onClick={onClick} />
        case "scan": return <FontAwesome.FaStepForward color="green" style={{cursor: "pointer"}} onClick={onClick} />
        case "test": return <FontAwesome.FaPlay color="green" style={{cursor: "pointer"}} onClick={onClick} />
        default:     return <FontAwesome.FaPlay color="white" style={{cursor: "pointer"}} onClick={onClick} />
    }
}

export default ActiveIcon
