import {handleActions} from "redux-actions"


const convertHashToMap = (hash) => {
    hash = hash || {}

    const result = []

    Object.keys(hash).forEach((key) => {
        result.push([key, hash[key]])
    })
    return result
}

function formatValueWithType(value, type) {

    if (typeof value === "string") {
        if (type === "boolean") {
            value = value.toLowerCase() === "true"
        } else if (type === "integer") {
            value = parseInt(value, 10)
        }
    }

    return value
}

const config = handleActions(
    {
        CONFIG_RECEIVED: (previousState, action) => {
            if (action.error) {
                return {
                    ...previousState,
                    error: action.payload.toString(),
                    success: null,
                    loading: false
                }
            }

            return {
                ...previousState,
                install: convertHashToMap(JSON.parse(action.payload.install)),
                uaa: JSON.parse(action.payload.uaa),
                complexTypeSchema: JSON.parse(action.payload.complex_type_schema),
                error: null,
                success: null,
                loading: false
            }
        },
        CONFIG_CLEARED: (previousState) => {
            return {
                ...previousState,
                install: null,
                uaa: null,
                error: null,
                success: null,
                complexTypeSchema: null,
                loading: false
            }
        },
        CONFIG_PROPERTIES_FIELDS_EDITED: (previousState, action) => {
            const nextState = {...previousState}
            let type = action.payload.propertyType.toLowerCase()
            let value = action.payload.propertyValue
            let name = action.payload.propertyName
            value = formatValueWithType(value, type)

            nextState.install.find(item => {
                if (item[0] === name) {
                    item[1].value = value
                    if (item[1].required) {
                        delete item[1].required
                    }

                    return true
                }
                return false
            })

            return nextState
        },
        CONFIG_UAA_FIELDS_EDITED: (previousState, action) => {
            const nextState = {...previousState}
            nextState.uaa[action.payload.index][action.payload.uaaAttribute] = action.payload.uaaValue
            return nextState
        },
        CONFIG_SAVED: (previousState, action) => {
            if (action.error) {
                return {
                    ...previousState,
                    error: action.payload.toString()
                }
            }

            return {
                ...previousState,
                success: "Config save succeeded!"
            }
        },
        UAA_FIELD_REMOVED: (previousState, action) => {
            const nextState = {...previousState}
            nextState.uaa.splice(action.payload.index, 1)
            return nextState
        },
        UAA_FIELD_ADDED: (previousState) => {
            const nextState = {...previousState}
            nextState.uaa.push({username: "", password: "", groups: []})
            return nextState
        },
        PROPERTY_JSON_EDITED: (previousState, action) => {
            let json_obj

            try {
                json_obj = convertHashToMap(JSON.parse(action.payload.data))
            } catch(error) {
                return {
                    ...previousState,
                    error: "Bad Json!"
                }
            }

            return {
                ...previousState,
                install: json_obj,
                error: null
            }
        },
        TILE_TESTED: (previousState, action) => {
            if (action.error) {
                return {
                    ...previousState,
                    error: action.payload.toString()
                }
            }

            return {
                ...previousState,
                success: previousState.success + " Tile CI begun!"
            }
        },
        CONFIG_CONTENT_LOADING: () => {
            return {
                loading: true
            }
        }
    },
    {
        install: null,
        uaa: null,
        error: null,
        success: null,
        loading: false
    }
)

export default config
