import React, {Component} from "react"
import {connect} from "react-redux"
import {push} from "connected-react-router"
import {Container, Table, Label} from "semantic-ui-react"
import {getScanRules, receiveScanRules} from "../actions/scan"
import {ScanStateIcon} from "../components/ScanStateLabel"

class Scan extends Component {
    componentDidMount() {
        this.props.getScanRules()
    }

    render() {
        const rules = this.props.rules
        if (!rules) return null
        return (
            <Container fluid style={{padding: "0em 1em"}}>
                <Table compact basic selectable>
                    <Table.Body>
                        { rules.map( rule => (
                            <Table.Row key={rule.id}
                                verticalAlign='top'
                                onClick={() => this.props.push(`/scan/${rule.id}`)}
                                style={{cursor: "pointer"}}>
                                <Table.Cell singleLine collapsing>
                                    <ScanStateIcon style={{fontSize: "large", marginRight: "10px"}} level={rule.level}/>
                                    <b>{rule.id}</b>
                                </Table.Cell>
                                <Table.Cell collapsing>
                                    <Label size="tiny">{rule.count}</Label>
                                </Table.Cell>
                                <Table.Cell>
                                    <i>{rule.message}</i>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        rules: state.scan.list,
    })
}

const mapActionsToProps = {
    receiveScanRules,
    getScanRules,
    push
}

export default connect(mapStateToProps, mapActionsToProps)(Scan)
