import React, {Component} from "react"
import {connect} from "react-redux"
import {getTileMetadata, receiveTileMetadata} from "../actions/tiles"
import Highlight from "react-highlight"
import {Segment, Label} from "semantic-ui-react"

class TileMetadata extends Component {
    componentDidMount() {
        this.props.getTileMetadata(this.props.slug, this.props.releaseId)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.slug !== this.props.slug || nextProps.releaseId !== this.props.releaseId) {
            this.props.getTileMetadata(nextProps.slug, nextProps.releaseId)
        }
    }

    render() {
        if (!this.props.metadata) return null
        return (
            <Segment raised style={{fontSize: "small"}}>
                <Label ribbon color="blue">Info</Label>
                <h5>Tile Metadata</h5>
                <Highlight>
                    {this.props.metadata}
                </Highlight>
            </Segment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return ({
        slug: ownProps.slug,
        releaseId: ownProps.releaseId,
        metadata: state.tiles.tileMetadata
    })
}

const mapActionsToProps = {
    receiveTileMetadata,
    getTileMetadata
}

export default connect(mapStateToProps, mapActionsToProps)(TileMetadata)
