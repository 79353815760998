import {createAction} from "redux-actions"
import * as HTTPStatus from "http-status-codes"
import {FetchOptions} from "../client-common/actions/common"

export const getTileContent = (path) => {
    return dispatch => {
        dispatch(startLoadingTileContent())
        if (path) path = "/" + path
        else path = ""
        return fetch(`/api/browse${path}`, FetchOptions).then(async response => {
            if (response.status === HTTPStatus.OK) {
                return response.json()
            }

            const content = await response.text()
            return Promise.resolve({
                type: "error",
                error: content
            })
        }).then(result => {
            dispatch(receiveTileContent(result))
        }).catch(error => {
            dispatch(receiveTileContent(error))
        })
    }
}

export const startLoadingTileContent = createAction("TILE_CONTENT_LOADING")
export const receiveTileContent = createAction("TILE_CONTENT_RECEIVED")
