import connect from "react-redux/es/connect/connect"
import AbsoluteRedirect from "../components/util/AbsoluteRedirect"

const mapStateToProps = (state) => {
    return {
        to: state.user.authURI + "?next=" + window.location
    }
}

export default connect(mapStateToProps)(AbsoluteRedirect)
