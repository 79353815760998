export const acl = state => {
    const user = state.user

    if (user.authorization !== "authorized")
        return []

    let accessList = ["tiles"]

    if (user.is_admin)
        accessList.push("jobs","scan")

    return accessList
}
