import React, {Component, Fragment} from "react"
import Poller from "../containers/Poller"
import TileContent from "../containers/TileContent"
import TileGrid from "../containers/TileGrid"
import Tiles from "../containers/Tiles"
import Scan from "../containers/Scan"
import ScanRule from "../containers/ScanRule"
import TileReleases from "../containers/TileReleases"
import TileReleaseDetails from "../containers/TileReleaseDetails"
import Jobs from "../containers/Jobs"
import Job from "../containers/Job"
import {AuthorizedRouter, NotAuthorized, Authorized, Pending} from "../client-common"
import {ConnectedRouter} from "connected-react-router"
import {Route, Redirect} from "react-router"
import {getMatrix} from "../actions/matrix"
import {Loader} from "semantic-ui-react"
import AuthServerRedirect from "../containers/AuthServerRedirect"
import TopMenu from "../containers/TopMenu"
import "semantic-ui-css/semantic.min.css"


const defaultRoute = "/tiles"

class App extends Component {
    render() {
        return (
            <div className="app">
                <Poller
                    selectEnabled={state => state.matrix.activePollers.length > 0}
                    actionBuilder={state => getMatrix(state.matrix.activePollers[0], state.matrix.last_updated)}/>
                <TopMenu history={this.props.history} defaultRoute={defaultRoute}/>
                <AuthorizedRouter>
                    <Authorized>
                        <ConnectedRouter history={this.props.history}>
                            <Fragment>
                                <Route exact path="/" render={() => (
                                    <Redirect to={defaultRoute}/>
                                )}/>
                                <Route exact path="/browse" component={TileContent}/>
                                <Route path="/browse/**" component={TileContent}/>
                                <Route exact path="/jobs" component={Jobs}/>
                                <Route exact path="/job/:jobId" component={Job}/>
                                <Route exact path="/scan" component={Scan}/>
                                <Route exact path="/scan/:ruleId" component={ScanRule}/>
                                <Route exact path="/tilegrid" component={TileGrid}/>
                                <Route exact path="/tiles" component={Tiles}/>
                                <Route exact path="/tiles/:slug" component={TileReleases}/>
                                <Route exact path="/tiles/:slug/:release" component={TileReleaseDetails}/>
                                <Route exact path="/tiles/:slug/:release/:tab" component={TileReleaseDetails}/>
                                <Route exact path="/tiles/:slug/releases/:release/config" render={(props) => (
                                    <Redirect
                                        to={"/tiles/" + props.match.params.slug + "/" + props.match.params.release + "/config"}/>
                                )}/>
                                <Route exact path="/matrix" render={(/* props */) => (<Redirect to={"/tiles"}/>)}/>
                                <Route path="/matrix/**"
                                    render={(props) => (<Redirect to={"/tiles/" + props.match.params[0]}/>)}/>
                            </Fragment>
                        </ConnectedRouter>
                    </Authorized>
                    <NotAuthorized>
                        <AuthServerRedirect/>
                    </NotAuthorized>
                    <Pending>
                        <Loader indeterminate active>Loading</Loader>
                    </Pending>
                </AuthorizedRouter>
            </div>
        )
    }
}

export default App
