import React, {Component, Fragment} from "react"
import {connect} from "react-redux"
import {Link} from "react-router-dom"
import {withRouter} from "react-router"
import {Image, Loader, Message} from "semantic-ui-react"
import {getMatrix, receiveMatrix, startMatrixPolling, stopMatrixPolling, toggleActive} from "../actions/matrix"
import classNames from "classnames"
import queryString from "query-string"
import * as FontAwesome from "react-icons/fa"
import {StatusIcon} from "../components/StatusLabel"
import {ActiveIcon} from "../components/ActiveIcon"
import {matrixColumns} from "../selectors/matrix/columns"

// For each column, we have the ability to override the default
// - class
// - value formatting for display
// - value normalization for sorting
// - url for link target

const cellClass = (row, column, cell) => {
    switch (column.property) {
        case "metadata_name":
            return {"name_mismatch": cell.value !== row["slug"].value}
        default:
            return cell && cell.status
    }
}

const cellDisplayValue = (row, column, cell, toggleActive) => {
    switch (column.property) {
        case "stemcell_version": {
            let stemcellImageSource = "/stemcell_" + row.stemcell_os.value + "-logo.png"
            return (
                <Fragment>
                    {
                        row.stemcell_os.value &&
                        <Image className="os-logo" src={stemcellImageSource} alt={row.stemcell_os.value}/>
                    }
                    {cell.value}
                    {row.stemcell_os.value && !row.stemcell_float_minor.value &&
                    <FontAwesome.FaThumbtack className="pin"/>}
                </Fragment>
            )
        }
        case "active": {
            return (
                <ActiveIcon
                    status={cell.status}
                    is_latest={row["is_latest"].value}
                    onClick={() => toggleActive(row.slug.value, row.release_id.value)}
                />
            )
        }
        default: {
            if (cell && cell.value) return cell.value
            if (cell) return (<StatusIcon status={cell.status}/>)
            return null
        }
    }
}

const statusOrder = {
    "shipit": 1,
    "in-progress": 2,
    "ready": 3,
    "untested": 4,
    "blocked": 5,
    "locked": 6,
    "warning": 7,
    "failed": 8,
    "help!": 9
}

const normalizeNumber = (value) => {
    if (value === null) return null
    if (value === undefined) return null
    let parts = value.split(".")
    for (var p = 0; p < parts.length; p++) parts[p] = "0000000000000000".substr((parts[p] + ".").search(/[^0-9]/)) + parts[p]
    return parts.join(".")
}

const normalizeSemver = (value) => {
    if (value === null) return null
    if (value === undefined) return null
    let parts = value.split("-")
    for (var p = 0; p < parts.length; p++) parts[p] = normalizeNumber(parts[p])
    if (parts.length < 2) parts.push("~~~~~~")
    return parts.join("-")
}

const cellSortValue = (row, column, cell) => {
    switch (column.property) {
        case "version":
            return normalizeSemver(cell.value)
        default:
            if (column.is_numeric) return normalizeNumber(cell.value)
            if (column.is_property)
                if (cell && cell.value) return cell.value
                else return null
            if (cell && cell.status) return statusOrder[cell.status] || 99
            return null
    }
}

const cellLinkValue = (row, column, cell, basePath) => {
    switch (column.property) {
        case "active": {
            return null
        }
        default: {
            let href = basePath + "/" + row.slug.value + "/" + row.release_id.value
            if (column.tab) href += "/" + column.tab
            return href
        }
    }
}

const ConditionalLink = (props) => {
    if (!props.to) return (<Fragment> {props.children} </Fragment>)
    return (<Link to={props.to}> {props.children} </Link>)
}

const MatrixCell = ({row, column, cell, basePath, toggleActive}) => (
    <div className={classNames("matrix-cell", column.property, cellClass(row, column, cell))}>
        <ConditionalLink to={cellLinkValue(row, column, cell, basePath)}>
            {cellDisplayValue(row, column, cell, toggleActive)}
        </ConditionalLink>
    </div>
)

const MatrixBody = ({matrix, basePath, toggleActive}) => (
    <div>
        {matrix.rows.length === 0 &&
        <Message negative>
            <Message.Header>No tiles</Message.Header>
            <p>You do not have permission to view any tiles.</p>
            <p>This can occur if you don't have permission to the Auth App in Okta OR have not signed in to the Broadcom Okta portal</p>
        </Message>
        }

        <div className="matrix-body">
            {matrix.rows.map(function (row, rownum) {
                return (
                    <div key={rownum} className="matrix-row">
                        {matrix.columns.map(function (column, colnum) {
                            return (
                                <MatrixCell
                                    key={colnum}
                                    row={row}
                                    column={column}
                                    cell={row[column.property]}
                                    basePath={basePath}
                                    toggleActive={toggleActive}
                                />
                            )
                        })}
                    </div>
                )
            })}
        </div>
    </div>
)

const columnLabelClass = (column) => {
    return classNames(
        "column-label",
        column.property
    )
}

const ColumnSortState = ({column, sortBy}) => {
    const sortDirection = (sortBy && sortBy.startsWith("-")) ? "-" : "+"
    if (sortBy && sortBy.startsWith("-")) sortBy = sortBy.slice(1)
    if (sortBy && sortBy === column.property) return (
        <div className="column-state">
            {sortDirection === "-" && <FontAwesome.FaSortUp/>}
            {sortDirection === "+" && <FontAwesome.FaSortDown/>}
        </div>
    )
    else return null
}

const columnSortTarget = (column, sortBy) => {
    const sortDirection = (sortBy && sortBy.startsWith("-")) ? "-" : "+"
    if (sortBy && sortBy.startsWith("-")) sortBy = sortBy.slice(1)
    if (sortBy && sortBy === column.property)
        switch (sortDirection) {
            case "-":
                return ""
            default:
                return "-" + column.property
        }
    return column.property
}

const ColumnLabels = ({matrix, sortBy}) => (
    <div className={"column-labels"}>
        {matrix.columns.map(function (column, colnum) {
            return (
                <div key={colnum} className={columnLabelClass(column)}>
                    <Link className="column-label" to={"?sortBy=" + columnSortTarget(column, sortBy)}>
                        <span>{column.display_name}</span>
                    </Link>
                    <ColumnSortState column={column} sortBy={sortBy}/>
                </div>
            )
        })}
    </div>
)

const rowLabelClass = (matrix) => {
    return classNames(
        "row-label",
        matrix.columns[0].property
    )
}

const rowLabel = (matrix, row, basePath) => {
    switch (matrix.columns[0].property) {
        case "slug":
            return (
                <Link to={basePath + "/" + row.slug.value}>
                    <span>{row.slug.value}</span>
                </Link>
            )
        case "version":
            return (
                <Link to={basePath + "/" + row.slug.value + "/" + row.release_id.value}>
                    <span>{row.version.value}</span>
                </Link>
            )
        default:
            return (<span>{row[matrix.columns[0].name]}</span>)
    }
}

const RowLabels = ({matrix, basePath}) => (
    <div className="row-labels">
        {matrix.rows.map(function (row, rownum) {
            return (
                <div key={rownum} className={rowLabelClass(matrix)}>
                    <div className={matrix.columns[0].name}>
                        {rowLabel(matrix, row, basePath)}
                    </div>
                </div>
            )
        })}
    </div>
)

class Matrix extends Component {
    componentDidMount() {
        let path = this.props.path
        this.props.getMatrix(path)
        this.props.startMatrixPolling(path)
    }

    componentWillUnmount() {
        let path = this.props.path
        this.props.stopMatrixPolling(path)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.path !== this.props.path) {
            this.props.stopMatrixPolling(this.props.path)
            this.props.getMatrix(nextProps.path)
            this.props.startMatrixPolling(nextProps.path)
        }
    }

    render() {
        return (
            <div className="matrix">
                {this.props.matrix.loading && <Loader indeterminate active/>}
                {!this.props.matrix.loading &&
                <Fragment>
                    <div className="scroll-area">
                        <ColumnLabels {...this.props} />
                        <MatrixBody {...this.props} />
                    </div>
                    <RowLabels {...this.props}/>
                </Fragment>
                }
            </div>
        )
    }
}

const normalizeMatrix = (matrix) => {
    matrix["_columns"] = {}
    for (var t = 0; t < matrix.columns.length; t++) {
        matrix["_columns"][matrix.columns[t].property] = matrix.columns[t]
    }
    return matrix
}

const sortMatrix = (matrix, sortBy) => {
    let sortOrder = 1
    if (matrix.columns.length < 1) return matrix
    if (!sortBy) sortBy = matrix.columns[0].property
    if (sortBy.startsWith("-")) {
        sortBy = sortBy.slice(1)
        sortOrder = -sortOrder
    }
    const column = matrix["_columns"][sortBy]
    if (!column) return matrix
    for (var r = 0; r < matrix.rows.length; r++) {
        matrix.rows[r]["_sort"] = cellSortValue(matrix.rows[r], column, matrix.rows[r][sortBy])
    }
    matrix.rows = matrix.rows.sort(function (a, b) {
        // Always sort nulls to the end
        if (a["_sort"] === null)
            if (b["_sort"] === null) return 0
            else return 1
        else if (b["_sort"] === null) return -1
        if (a["_sort"] > b["_sort"]) return sortOrder
        if (a["_sort"] < b["_sort"]) return -sortOrder
        // TODO - Within equals, sort on the default (column[0])
        return 0
    })
    return matrix
}

const filterMatrix = (matrix, filter) => {
    if (!filter) return matrix
    const filterOn = filter.toLowerCase()
    return Object.assign({}, matrix, {
        rows: matrix.rows.filter((row) => {
            for (let c in row) {
                const cell = row[c]
                if (typeof(cell) !== "object")
                    continue
                if ("value" in cell && cell.value !== null) {
                    if (cell.value.toString().toLowerCase().includes(filterOn))
                        return true
                }
            }
            return false
        })
    })
}


const mapStateToProps = (state, ownProps) => {
    const basePath = ownProps.match.path.replace(/\/[:*].*/, "")
    const queryParams = queryString.parse(ownProps.location.search)
    const sortBy = queryParams["sortBy"] || ownProps.sortBy
    let matrix = {...state.matrix}
    matrix.columns = matrixColumns(matrix.columns)

    return ({
        matrix: sortMatrix(normalizeMatrix(filterMatrix(matrix, state.tiles.filter)), sortBy),
        basePath: basePath,
        path: ownProps.match.url.slice(basePath.length),
        sortBy: sortBy
    })
}

const mapActionsToProps = {
    startMatrixPolling,
    stopMatrixPolling,
    receiveMatrix,
    getMatrix,
    toggleActive
}

export default withRouter(connect(mapStateToProps, mapActionsToProps)(Matrix))

