import React, {Component, Fragment} from "react"
import {connect} from "react-redux"
import {getLogs, receiveLogs} from "../actions/jobs"
import {Divider, Accordion, Icon, Label, Button, Message} from "semantic-ui-react"

const Pre = ({content}) => (
    <div style={{whiteSpace: "pre-wrap", wordWrap: "break-word", fontFamily: "monospace", fontSize: ".8em"}}>
        {content}
    </div>
)

const StatusLabel = ({status}) => {
    var color, text
    if (status === 0) { color="green"; text = "success" }
    else if (!status) { color="grey"; text = "..." }
    else { color="red"; text = "exit status " + status }
    return ( <Label color={color} size="small" style={{paddingTop: "3px", position: "relative", top: "-2px", marginRight: "6px" }}>{text}</Label> )
}

class LogSection extends Component {

    constructor(props) {
        super(props)
        this.state = {active: false}
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick() {
        this.setState({active: !this.state.active})
    }

    render() {
        const active = this.state.active
        const section = this.props
        return (
            <Fragment>
                <Accordion.Title active={active} onClick={this.handleClick}>
                    <Icon name='dropdown'/>
                    <StatusLabel status={section.exit_status}/>
                    <span style={{fontWeight: "bold"}}>{section.name}</span>
                    {section.duration > 0 && <span style={{marginLeft: "4px"}}>({section.duration} seconds)</span>}
                </Accordion.Title>
                <Accordion.Content active={active} style={{paddingLeft: "24px"}}>
                    {section.content && <Pre content={section.content}/>}
                    {section.sections && <StructuredLog sections={section.sections}/>}
                </Accordion.Content>
            </Fragment>
        )
    }
}

const StructuredLog = ({sections}) => (
    <Accordion fluid>
        {sections.map((section, key) => (<LogSection key={key} {...section} />))}
    </Accordion>
)

class JobLogs extends Component {
    componentDidMount() {
        if (this.props.jobId) this.props.getLogs(this.props.jobId)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.jobId !== this.props.jobId && nextProps.jobId) {
            this.props.getLogs(nextProps.jobId)
        }
    }

    render() {
        if (this.props.logsError) {
            return (
                <Fragment>
                    <Divider/>
                    <Message negative>
                        <Message.Header>Logs Hidden</Message.Header>
                        <p>Partner test logs are sensitive material</p>
                        <p>If you need to see the logs, please contact a member of the Platform Engineering team</p>
                    </Message>
                </Fragment>
            )
        }

        const logs = this.props.logs
        if (!logs || logs.length === 0) return null
        return (
            <Fragment>
                <Divider/>
                <a href={"/api/jobs/" + this.props.jobId + "/logs"}>
                    <Button className="retryButton" size="tiny" icon labelPosition='left' primary>
                        <Icon name='download'/>
                        Raw Log
                    </Button>
                </a>
                <StructuredLog sections={logs}/>
            </Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return ({
        jobId: ownProps.jobId,
        logs: state.jobs.logs,
        logsError: state.jobs.logsError
    })
}

const mapActionsToProps = {
    receiveLogs,
    getLogs
}

export default connect(mapStateToProps, mapActionsToProps)(JobLogs)
