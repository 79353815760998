import React, {Fragment, useEffect} from "react"
import Menu from "../components/isv-ci-common-ui/Menu"
import {authorize} from "../client-common/actions/user"
import {connect} from "react-redux"
import Header from "./MainMenu"

const TopMenu = ({history, defaultRoute, authorize, authorization, user}) => {
    useEffect(() => {
        if (authorization === "pending") {
            authorize()
        }
    })

    return (
        <Fragment>
            <Menu appID="dashboard" user={user}>
                <Header history={history} defaultRoute={defaultRoute}/>
            </Menu>
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user || {name: "..."},
        authorization: state.user.authorization
    }
}

const mapDispatchToProps = {
    authorize
}

export default connect(mapStateToProps, mapDispatchToProps)(TopMenu)




