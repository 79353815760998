import {createAction} from "redux-actions"
import * as HTTPStatus from "http-status-codes"
import {FetchOptions} from "../client-common/actions/common"

export const getTiles = () => {
    return dispatch => {
        dispatch(receiveTiles(null)) // Erase prior result
        const url = "/api/tiles"
        return fetch(url, FetchOptions).then(response => {
            if (response.status === HTTPStatus.OK) {
                return response.json()
            }
            return Promise.reject(Error(`HTTP Status Code: ${response.status}`))
        }).then(result => {
            dispatch(receiveTiles(result))
        }).catch(error =>{
            dispatch(receiveTiles(error))
        })
    }
}

export const getTile = (slug) => {
    return dispatch => {
        dispatch(receiveTile({})) // Erase prior result
        const url = "/api/tiles/" + slug
        return fetch(url, FetchOptions).then(response => {
            if (response.status === HTTPStatus.OK) {
                return response.json()
            }
            return Promise.reject(Error(`HTTP Status Code: ${response.status}`))
        }).then(result => {
            dispatch(receiveTile(result))
        }).catch(error =>{
            dispatch(receiveTile(error))
        })
    }
}

export const getTileRelease = (slug, releaseId) => {
    return dispatch => {
        dispatch(receiveTileRelease({})) // Erase prior result
        const url = "/api/tiles/" + slug + "/releases/" + releaseId
        return fetch(url, FetchOptions).then(response => {
            if (response.status === HTTPStatus.OK) {
                return response.json()
            }
            return Promise.reject(Error(`HTTP Status Code: ${response.status}`))
        }).then(result => {
            dispatch(receiveTileRelease(result))
        }).catch(error =>{
            dispatch(receiveTileRelease(error))
        })
    }
}

export const getTileMetadata = (slug, releaseId) => {
    return dispatch => {
        dispatch(receiveTileMetadata(null)) // Erase prior result
        const url = "/api/tiles/" + slug + "/releases/" + releaseId + "/metadata"
        return fetch(url, FetchOptions).then(response => {
            if (response.status === HTTPStatus.OK) {
                return response.text()
            }
            return Promise.reject(Error(`HTTP Status Code: ${response.status}`))
        }).then(result => {
            dispatch(receiveTileMetadata(result))
        }).catch(error =>{
            dispatch(receiveTileMetadata(error))
        })
    }
}

export const getScanResults = (slug, releaseId) => {
    return dispatch => {
        dispatch(receiveScanResults(null)) // Erase prior result
        const url = "/api/tiles/" + slug + "/releases/" + releaseId + "/scan"
        return fetch(url, FetchOptions).then(response => {
            if (response.status === HTTPStatus.OK) {
                return response.json()
            }
            return Promise.reject(Error(`HTTP Status Code: ${response.status}`))
        }).then(result => {
            dispatch(receiveScanResults(result))
        }).catch(error =>{
            dispatch(receiveScanResults(error))
        })
    }
}

export const updateScanHit = (hit, update) => {
    return dispatch => {
        const url = "/api/scan-rules/hit/" + hit.scan_rule_id + "/" + hit.release_id
        const data = Object.assign({}, hit, update)
        dispatch(receiveScanHit(data)) // Immediately refect state update
        return fetch(url, {
            ...FetchOptions,
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data)}).then(response => {
            if (response.status === HTTPStatus.OK) {
                return response.json()
            }
            return Promise.reject(Error(`HTTP Status Code: ${response.status}`))
        }).then((/* result */) => {
            // No-op
        }).catch((/* error */) =>{
            dispatch(receiveScanHit(hit))
        })
    }
}

export const receiveTiles = createAction("TILES_RECEIVED")
export const filterTiles = createAction("TILES_FILTER")
export const receiveTile = createAction("TILE_RECEIVED")
export const receiveTileRelease = createAction("TILE_RELEASE_RECEIVED")
export const receiveTileMetadata = createAction("TILE_METADATA_RECEIVED")
export const receiveScanResults = createAction("SCAN_RESULTS_RECEIVED")
export const receiveScanHit = createAction("SCAN_HIT_RECEIVED")
