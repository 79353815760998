import { combineReducers } from "redux"
import user from "./user"
import tileContent from "./tileContent"
import { connectRouter } from "connected-react-router"
import config from "./config"
import matrix from "./matrix"
import tiles from "./tiles"
import jobs from "./jobs"
import scan from "./scan"

const reducer = (history) => combineReducers({
    user,
    config,
    router: connectRouter(history),
    tileContent,
    matrix,
    tiles,
    jobs,
    scan
})

export default reducer
