import React, {Component, Fragment} from "react"
import {connect} from "react-redux"
import {Link} from "react-router-dom"
import {withRouter} from "react-router"
import Matrix from "../containers/Matrix"
import {Icon, Message} from "semantic-ui-react"
import {getTileRelease, receiveTileRelease} from "../actions/tiles"
import JobHistory from "../containers/JobHistory"
import TileMetadata from "../containers/TileMetadata"
import ScanResults from "../containers/ScanResults"
import Config from "../containers/Config"
import TileIcon from "../components/TileIcon"

const ReleaseTabDetailsPage = props => {
    const row = props.matrix && props.matrix.rows && props.matrix.rows[0]
    if (!row) return null
    const cell = row[props.tab]
    if (!cell) return null
    const slug = props.slug
    const isAdmin = props.isAdmin
    switch (props.tab) {
        case "metadata":
            return (
                <div className="details">
                    <TileMetadata slug={slug} releaseId={cell.release_id}/>
                </div>
            )
        case "scan":
            if (["shipit", "warning", "found-errors"].includes(cell.status)) return (
                <div className="details">
                    <ScanResults slug={slug} releaseId={cell.release_id} isAdmin={isAdmin}/>
                </div>
            )
            break // Display job history instead
        case "config":
            if (!isAdmin) return (
                <Message negative>
                    <Message.Header>Configuration Hidden</Message.Header>
                    <p>Partner configurations contain sensitive material</p>
                    <p>If you need to see the configuration file, please contact a member of the Platform Engineering team</p>
                </Message>
            )
            return (
                <div className="details">
                    <Config/>
                </div>
            )
        default:
            break // Display job history
    }
    return (
        <div className="details">
            <JobHistory cell={cell} isAdmin={isAdmin}/>
        </div>
    )
}

class TileReleaseDetails extends Component {
    componentDidMount() {
        this.props.getTileRelease(this.props.slug, this.props.releaseId)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.slug !== this.props.slug || nextProps.releaseId !== this.props.releaseId) {
            this.props.getTileRelease(nextProps.slug, nextProps.releaseId)
        }
    }

    render() {
        const slug = this.props.slug
        const releaseId = this.props.releaseId
        const version = this.props.release && this.props.release.version
        return (
            <Fragment>
                <h2 className="matrix-title">
                    <Link to={"/tiles/" + slug}><Icon name="chevron left"/></Link>
                    <TileIcon height="32" slug={slug} releaseid={releaseId}
                        style={{verticalAlign: "text-bottom", marginRight: "12px"}}/>
                    {slug} {version}
                </h2>
                <Matrix/>
                {this.props.tab && <ReleaseTabDetailsPage {...this.props} />}
            </Fragment>
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return ({
        matrix: state.matrix,
        release: state.tiles.tileRelease,
        slug: ownProps.match.params.slug,
        releaseId: ownProps.match.params.release,
        tab: ownProps.match.params.tab,
        isAdmin: state.tiles.tileRelease && state.tiles.tileRelease.is_admin,
    })
}

const mapActionsToProps = {
    receiveTileRelease,
    getTileRelease
}

export default withRouter(connect(mapStateToProps, mapActionsToProps)(TileReleaseDetails))
