import React, {Component} from "react"
import {connect} from "react-redux"
import {Container, Table, Loader} from "semantic-ui-react"
import {getJobs, receiveJobs} from "../actions/jobs"
import StatusLabel from "../components/StatusLabel"
import {Link} from "react-router-dom"

class Jobs extends Component {
    componentDidMount() {
        this.props.getJobs()
    }

    render() {
        const jobs = this.props.jobs.list
        return (
            <Container fluid style={{padding: "0em 1em"}}>
                { this.props.jobs.loading && <Loader indeterminate active /> }
                { !this.props.jobs.loading &&
                    <Table compact basic singleLine>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Job ID</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                <Table.HeaderCell>Target</Table.HeaderCell>
                                <Table.HeaderCell>Tile Release</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            { jobs && jobs.map( job => (
                                <Table.Row key={job.id} verticalAlign='top'>
                                    <Table.Cell>
                                        <Link to={"/tiles/" + job.slug + "/" + job.release_id + "/" + job.target_name + "?job=" + job.id}>{job.id}</Link>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <StatusLabel size="tiny" status={job.status}/>
                                    </Table.Cell>
                                    <Table.Cell>
                                        { job.job_type === "test" && <span>test on {job.target_name}</span> }
                                        { job.job_type !== "test" && <span>{job.job_type}</span> }
                                    </Table.Cell>
                                    <Table.Cell>
                                        <span>{job.slug}</span>
                                        <span>{job.version}</span>
                                    </Table.Cell>
                                </Table.Row>                               
                            ))}
                        </Table.Body>
                    </Table>
                }
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        jobs: state.jobs,
    })
}

const mapActionsToProps = {
    receiveJobs,
    getJobs
}

export default connect(mapStateToProps, mapActionsToProps)(Jobs)
