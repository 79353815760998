import React, {Component, Fragment} from "react"
import {connect} from "react-redux"
import {Link} from "react-router-dom"
import {withRouter} from "react-router"
import Matrix from "../containers/Matrix"
import {Icon} from "semantic-ui-react"
import {getTile, receiveTile} from "../actions/tiles"
import TileIcon from "../components/TileIcon"

class TileReleases extends Component {
    componentDidMount() {
        this.props.getTile(this.props.slug)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.slug !== this.props.slug) {
            this.props.getTile(nextProps.slug)
        }
    }

    render() {
        const slug = this.props.slug
        return (
            <Fragment>
                <h2 className="matrix-title">
                    <Link to="/tiles"><Icon name="chevron left"/></Link>
                    <Link to={ "/tiles/" + slug}>
                        <TileIcon height="32" slug={slug} style={{verticalAlign: "text-bottom", marginRight: "12px"}} />
                        {slug}
                    </Link>
                </h2>
                <Matrix sortBy={"-version"}/>
            </Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return ({
        tile: state.tiles.tile,
        slug: ownProps.match.params.slug
    })
}

const mapActionsToProps = {
    receiveTile,
    getTile
}

export default withRouter(connect(mapStateToProps, mapActionsToProps)(TileReleases))
