import {handleActions} from "redux-actions"

const tileContent = handleActions(
    {
        TILE_CONTENT_RECEIVED: (previousState, action) => {
            if (action.error) {
                return {
                    ...previousState,
                    error: action.payload.toString(),
                    loading: false,
                }
            }

            return {
                ...previousState,
                ...action.payload,
                loading: false,
            }
        },
        TILE_CONTENT_LOADING: () => {
            return {
                loading: true
            }
        }
    },
    {
        loading: false
    }
)

export default tileContent
