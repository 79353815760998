import {handleActions} from "redux-actions"

const jobs = handleActions(
    {
        JOBS_LOADING: (previousState) => {
            return {
                ...previousState,
                loading: true
            }
        },
        JOBS_RECEIVED: (previousState, action) => {
            if (action.error) {
                return {
                    ...previousState,
                    error: action.payload.toString()
                }
            }

            return {
                ...previousState,
                list: action.payload,
                loading: false
            }
        },
        JOB_RECEIVED: (previousState, action) => {
            if (action.error) {
                return {
                    ...previousState,
                    error: action.payload.toString()
                }
            }

            return {
                ...previousState,
                job: action.payload,
                dependencies: null,
                logs: null
            }
        },
        LOGS_RECEIVED: (previousState, action) => {
            if (action.error) {
                return {
                    ...previousState,
                    logsError: action.payload.toString()
                }
            }

            return {
                ...previousState,
                logs: action.payload,
            }
        },
        JOB_DEPENDENCIES_RECEIVED: (previousState, action) => {
            if (action.error) {
                return {
                    ...previousState,
                    error: action.payload.toString()
                }
            }

            return {
                ...previousState,
                dependencies: action.payload,
            }
        }
    },
    {
        job: null,
        dependencies: null,
        list: null,
        logs: null
    }
)

export default jobs