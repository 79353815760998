import React, {Component} from "react"
import {connect} from "react-redux"
import {withRouter} from "react-router"
import JobSummary from "../containers/JobSummary"

class Job extends Component {
    render() {
        const jobId = this.props.jobId
        return (
            <div className="details">
                <JobSummary jobId={jobId}/>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return ({
        jobId: parseInt(ownProps.match.params.jobId)
    })
}

const mapActionsToProps = {}

export default withRouter(connect(mapStateToProps, mapActionsToProps)(Job))
