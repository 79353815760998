import {connect} from "react-redux"
import {PropertyRouter, Tag} from "../components/PropertyRouter"

export class NotAuthorized extends Tag {
    static filter(parentProperties) {
        return parentProperties.authorization === "forbidden" || parentProperties.authorization === "unauthorized"
    }
}

export class Authorized extends Tag {
    static filter(parentProperties) {
        return parentProperties.authorization === "authorized"
    }
}

export class Pending extends Tag {
    static filter(parentProperties) {
        return parentProperties.authorization === "pending"
    }
}

const mapStateToProps = (state) => {
    return {
        authorization: state.user.authorization
    }
}

export default connect(mapStateToProps)(PropertyRouter)
