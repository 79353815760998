import {handleActions} from "redux-actions"

const user = handleActions(
    {
        "AUTHORIZATION_RECEIVE": (previousState, action) => {
            const user = action.payload.user || { name: "" }

            let authURI = (action.payload.authorization === "unauthorized") ? action.payload.authURI : null

            return {
                ...previousState,
                name: user.name,
                groups: user.groups,
                authURI: authURI,
                authorization: action.payload.authorization,
                is_admin: user.is_admin,
            }
        }
    },
    {
        authorization: "pending",
        name: "",
        groups: [],
        authURI: null,
        is_admin: false,
    }
)

export default user
