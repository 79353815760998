import React, {Component} from "react"
import {connect} from "react-redux"
import {withRouter} from "react-router"
import {Container, Segment} from "semantic-ui-react"
import {getScanRule, receiveScanRule} from "../actions/scan"
import ScanStateLabel from "../components/ScanStateLabel"

class ScanRule extends Component {
    componentDidMount() {
        this.props.getScanRule(this.props.ruleId)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.ruleId !== this.props.ruleId) {
            this.props.getScanRule(nextProps.ruleId)
        }
    }

    render() {
        const rule = this.props.rule && this.props.rule.rule
        if (!rule) return null
        const hits = this.props.rule.hits
        return (
            <Container fluid style={{padding: "0em 1em"}}>
                <Segment.Group raised>
                    <Segment>
                        <ScanStateLabel size="tiny" ribbon level={rule.level}/>
                        <b>{rule.id}</b><br/><br/>
                        <p><i>{rule.message}</i></p>
                    </Segment>
                    <Segment>
                        { hits.map((hit, key) => ( <span key={key}>{hit.tile_slug}/{hit.version}<br/></span> ))}
                    </Segment>
                </Segment.Group>
            </Container>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return ({
        ruleId: ownProps.match.params.ruleId,
        rule: state.scan.rule,
    })
}

const mapActionsToProps = {
    receiveScanRule,
    getScanRule
}

export default withRouter(connect(mapStateToProps, mapActionsToProps)(ScanRule))
