import React, {Component, Fragment} from "react"
import {connect} from "react-redux"
import {withRouter} from "react-router"
import {
    addConfigProperty,
    addUAAField,
    editPropertiesFields,
    editPropertiesJSON,
    editUAAFields,
    getConfig,
    removeUAAField,
    saveConfig,
    testTile,
    startLoadingConfigContent
} from "../actions/config"
import {
    Button,
    Dropdown,
    Form,
    Icon,
    Input,
    Message,
    Radio,
    Segment,
    Tab,
    TextArea,
    Loader,
} from "semantic-ui-react"

export class Config extends Component {
    componentDidMount() {
        this.props.getConfig(
            this.props.match.params.slug,
            this.props.match.params.release
        )
    }

    render() {
        const panes = [
            {
                menuItem: "Tile Configuration", render: () =>
                    <Tab.Pane id="install-properties">
                        <PropertiesHelp/>
                        <PropertiesFormFields onChange={this.props.editPropertiesFields}
                            selectorSchema={this.props.complexTypeSchema}
                            install={this.props.install}>
                            {filterSelectorFields(this.props.complexTypeSchema, this.props.install)}
                        </PropertiesFormFields>
                    </Tab.Pane>
            },
            {
                menuItem: "Tile Configuration - Advanced", render: () =>
                    <Tab.Pane id="install-properties-json">
                        <JsonHelp/>
                        <PropertiesJsonField {...this.props}/>
                    </Tab.Pane>
            },
            {
                menuItem: "UAA Users", render: () =>
                    <Tab.Pane id="install-properties-json">
                        <UAAHelp/>
                        <UAAForm {...this.props}/>
                        <Segment>
                            <Icon name="add circle" onClick={() => this.props.addUAAField()}/>
                        </Segment>
                    </Tab.Pane>
            },
        ]

        return (
            <Fragment fluid>
                {this.props.loading &&
                <div>
                    <Loader indeterminate active>
                        Loading...
                    </Loader>
                </div>
                }
                <Form id="config-form" error success>
                    {this.props.error &&
                    <Message header="Error" content={this.props.error} error/>
                    }
                    {this.props.success &&
                    <Message header='Success' content={this.props.success} success/>
                    }
                    <span>
                        <div id="save-buttons-div">
                            <Button id="save-config-button" size="tiny" primary
                                onClick={() => this.props.saveConfig(
                                    this.props.match.params.slug,
                                    serialize(this.props.match.params.slug,
                                        this.props.install, this.props.uaa
                                    )
                                )}>Save</Button>
                            <Button id="save-config-and-test-button" size="tiny" primary
                                disabled={!this.propsValid()}
                                onClick={() => this.props.saveConfig(
                                    this.props.match.params.slug,
                                    serialize(this.props.match.params.slug,
                                        this.props.install, this.props.uaa
                                    ),
                                    true
                                )}>Save and Run</Button>
                        </div>
                    </span>
                    <Tab panes={panes}/>
                </Form>
            </Fragment>
        )
    }

    propsValid() {
        return allRequiredFieldsHaveValues(this.props.install, this.props.complexTypeSchema)
    }
}


// Form Components

const UAAForm = (props) => (
    <Fragment>
        {props.uaa.map((item, i) => (
            <Segment>
                <Icon name="trash" style={{float: "right"}} onClick={() => props.removeUAAField({index: i})}/>
                <UAAFormFields item={item} i={i} {...props}/>
            </Segment>
        ))}
    </Fragment>
)

const UAAFormFields = (props) => {
    let uaaFields = [{fieldName: "username", fieldValue: props.item.username},
        {fieldName: "password", fieldValue: props.item.password},
        {fieldName: "groups", fieldValue: props.item.groups, tip: "cloud_controller.admin_read_only,doppler.firehose"}]

    return uaaFields.map((field) => {
        const fieldId = field.fieldName + props.i
        return (
            <Form.Field key={fieldId}>

                <label style={{display: "inline"}}>{field.fieldName}</label>
                {
                    field.tip &&
                    <span className="example">
                        example:
                        <code>{field.tip}</code>
                    </span>
                }

                <Input type="text"
                    id={fieldId}
                    onInput={e => props.editUAAFields({
                        index: props.i,
                        uaaAttribute: field.fieldName,
                        uaaValue: e.target.value
                    })}
                    defaultValue={field.fieldValue}
                />
            </Form.Field>)
    })
}

// Field Components

const PropertiesJsonField = (props) => {
    return (
        <TextArea id="properties-json-text-area" autoHeight={true} type="text"
            onChange={
                e => props.editPropertiesJSON(
                    {data: e.target.value}
                )
            }
            defaultValue={prettyPrintJSON(props.install)}/>
    )
}

const ConfigField = (props) => {
    let fieldType = calcFieldType(props.type, props.value)

    if (fieldType === "boolean") {
        const property_opts = [
            {
                text: "true",
                value: true
            },
            {
                text: "false",
                value: false
            }
        ]
        return (
            <PropertyDropdown property_opts={property_opts}
                field_type={fieldType}
                {...props}/>
        )
    }

    if (fieldType === "selector") {
        return (
            <Segment>
                <SelectorRadio {...props}>
                    {props.children}
                </SelectorRadio>
            </Segment>
        )
    }

    if (fieldType === "dropdown_select" && props.property_option) {

        let formatted_property_options = props.property_option.map((option) => {
            return {
                text: option.label,
                value: option.name
            }
        })
        return (
            <PropertyDropdown property_opts={formatted_property_options}
                field_type={fieldType}
                {...props}/>
        )
    }

    let form_type = "text"

    if (fieldType === "integer" || fieldType === "port") {
        form_type = "number"
    }

    let inputId = "input-" + props.name

    let formValue = props.value

    let onInput = function (e) {
        props.onChange({
            propertyName: props.name,
            propertyValue: e.target.value,
            propertyType: fieldType
        })
    }

    if (typeof (props.value) === "object") {
        formValue = JSON.stringify(props.value)
        onInput = function (e) {
            let obj = {}

            let defaultColor = "black"

            if (e.target.style.color !== "red") {
                defaultColor = e.target.style.color
            }

            try {
                obj = JSON.parse(e.target.value)

                e.target.style.color = defaultColor

                props.onChange({
                    propertyName: props.name,
                    propertyValue: obj,
                    propertyType: fieldType
                })
            } catch (error) {
                e.target.style.color = "red"
            }
        }
    }

    let inputClass = "ui input properties-fields"

    if (props.required && !props.disabled) {
        inputClass = "ui input properties-fields field error"
    }

    return (
        //https://stackoverflow.com/questions/45698735/semantic-ui-input-element-in-error-state-inside-form
        <div className={inputClass}>
            <Input id={inputId}
                type={form_type}
                onInput={e => onInput(e)}
                defaultValue={formValue}
                disabled={props.disabled}/>
        </div>
    )
}

const PropertiesFormFields = (props) => {
    return props.children.map(([property_name, property_value]) =>
        <Form.Field key={property_name}>
            <label>{property_name}</label>
            <ConfigField
                type={property_value["type"]}
                name={property_name}
                value={property_value["value"]}
                required={property_value["required"]}
                property_option={property_value["options"]}
                {...props}>
                {props.children}
            </ConfigField>
        </Form.Field>
    )
}

// Field helpers

export const filterSelectorFields = (schemaMeta, propertyFields) => {
    const selectorFields = []

    schemaMeta &&
    schemaMeta.selector_schema &&
    schemaMeta.selector_schema.forEach(selector => {
        selector.selector_options.forEach(selector_option => {
            selectorFields.push(...selector_option.config_value_keys)
        })
    })

    return propertyFields.filter(item => {
        return !selectorFields.includes(item[0])
    })
}

export const calcFieldType = (type, value) => {
    if (type !== undefined) {
        return type
    }

    if (typeof (value) === "boolean") {
        return "boolean"
    }

    if (typeof (value) === "number") {
        return "integer"
    }

    return "string"
}

export const allRequiredFieldsHaveValues = (install, schema) => {

    const requiredFields = install.filter((item) => {
        return item[1].required
    })

    if (requiredFields.length > 0) {
        const selectors = install.filter((item) => {
            return item[1].type === "selector"
        })

        if (selectors.length === 0) {
            return false
        }

        const requiredSelectorKeys = []

        if (schema && schema.selector_schema) {
            selectors.forEach((item) => {
                const selectorSchema = schema.selector_schema.find((schemaItem) => {
                    return schemaItem.selector_key === item[0]
                })
                const selectorSchemaOption = selectorSchema && selectorSchema.selector_options.find((optionItem) => {
                    return optionItem.name === item[1].value
                })
                if (selectorSchemaOption) {
                    requiredSelectorKeys.push(...selectorSchemaOption.config_value_keys)
                }
            })
        }

        return requiredFields.find((item) => {
            return requiredSelectorKeys.includes(item[0])
        }) === undefined
    } else {
        return true
    }
}

class HelpMessage extends Component {

    constructor(props) {
        super(props)
        this.state = {open: true}
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick() {
        // console.log('clicked')
        this.setState({open: !this.state.open})
    }

    render() {
        const open = this.state.open
        if (open) return (
            <Message>
                {this.props.children}
                <Icon name="close" onClick={this.handleClick}/>
            </Message>
        )
        else return (
            <Fragment>
                <Icon name="help" onClick={this.handleClick} style={{float: "right"}}/>
                <br/><br/>
            </Fragment>
        )
    }
}

const PropertiesHelp = () => {
    return (
        <HelpMessage>
            <p>
                The Dashboard CI pipelines configure your tile using the &nbsp;
                <a rel="noopener noreferrer" target="_blank" className="decorate"
                    href="https://github.com/pivotal-cf/om">om</a>
                &nbsp; command (Ops Manager's cli).
            </p>
            <p>
                The form representation below attempts to make it as easy as possible for you to provide the
                required configuration parameters. The properties should correspond to the
                fields that appear in the Ops Manager GUI for your tile. Some of the more advanced Ops Manager
                features are not supported by this simplified form and require that you directly manipulate
                the JSON configuration data in the "Advanced" tab above.
            </p>
            <p>
                An error status in the <b>config</b> column above indicates that required fields are missing.
                Those fields also appear in red below. Your tile will not proceed through CI until values
                have been provided for all required fields.
            </p>
            <p>
                The following substitution strings may be used to reference properties that may vary between
                test environments:
            </p>
            <ul>
                <li><code>{"{"}az{"}"}</code> will be replaced with the name of an availability zone in the environment.
                </li>
                <li><code>{"{"}disk_type{"}"}</code> will be replaced with the name of a disk type in the environment.
                </li>
                <li><code>{"{"}vm_type{"}"}</code> will be replaced with the name of a vm type in the environment.</li>
            </ul>
        </HelpMessage>
    )
}

const JsonHelp = () => {
    return (
        <HelpMessage>
            <p>
                The Dashboard CI pipelines configure your tile using the &nbsp;
                <a rel="noopener noreferrer" target="_blank" className="decorate"
                    href="https://github.com/pivotal-cf/om">om</a>
                &nbsp; command (Ops Manager's cli).
            </p>
            <p>
                The JSON below is what will be passed to the <code>om configure-product</code> command.
            </p>
            <p>
                See
                <a rel="noopener noreferrer" target="_blank" className="decorate"
                    href="https://github.com/pivotal-cf/om/blob/master/docs/configure-product/README.md#user-content-configuring-the---product-properties">
                    om configure-product --product-properties documentation
                </a>
                for details on the expected format. Properties are further described in the
                <a rel="noopener noreferrer"
                    target="_blank"
                    className="decorate"
                    href="https://docs.pivotal.io/tiledev/reference.html#properties">Tile
                    Developer Guide
                </a>
            </p>
            <p>
                The following substitution strings may be used to reference properties that may vary between
                test environments:
            </p>
            <ul>
                <li><code>{"{"}az{"}"}</code> will be replaced with the name of an availability zone in the environment.
                </li>
                <li><code>{"{"}disk_type{"}"}</code> will be replaced with the name of a disk type in the environment.
                </li>
                <li><code>{"{"}vm_type{"}"}</code> will be replaced with the name of a vm type in the environment.</li>
            </ul>
        </HelpMessage>
    )
}

const UAAHelp = () => {
    return (
        <HelpMessage>
            A list of UAA users that need to pre-exist in the test environment for your product to function correctly.
        </HelpMessage>
    )
}

// Dropdown Component

const PropertyDropdown = (props) => {
    let inputId = "input-" + props.name

    return (
        <Dropdown id={inputId}
            onChange={(e, {value}) => props.onChange({
                propertyName: props.name,
                propertyValue: value,
                propertyType: props.field_type
            })}
            error={props.required && !props.disabled}
            defaultValue={props.value}
            selection options={props.property_opts}
        />
    )
}

const SelectorRadio = (props) => {

    const selector_item_schema = props.selectorSchema.selector_schema &&
        props.selectorSchema.selector_schema.find(item => {
            return item.selector_key === props.name
        })

    if (selector_item_schema === undefined) {
        return <Fragment>Unknown selector</Fragment>
    }

    return selector_item_schema.selector_options.map(selector_option => {
        const currentValue = props.value

        const selector_fields = props.install.filter(item => {
            return selector_option.config_value_keys.includes(item[0])
        })

        let inputId = "input-" + selector_option.name + "-" + props.name
        let isDisabled = selector_option.name !== currentValue

        return (<Form.Field>
            <Radio id={inputId}
                label={selector_option.name}
                value={selector_option.name}
                checked={selector_option.name === props.value}
                name={props.name}
                onChange={() => props.onChange({
                    propertyName: props.name,
                    propertyValue: selector_option.name,
                    propertyType: "selector"
                })}
            />
            {selector_fields.length > 0 &&
            <Segment disabled={isDisabled}>
                <PropertiesFormFields
                    onChange={props.onChange}
                    complexTypeSchema={props.complexTypeSchema}
                    selectorSchema={props.selectorSchema}
                    disabled={isDisabled}>
                    {selector_fields}
                </PropertiesFormFields>
            </Segment>}
        </Form.Field>)
    })
}

// Helpers

const serialize = (slug, install, uaa) => {
    // console.log(install)
    const result = {
        install: {},
        tile_slug: slug,
        uaa: uaa
    }

    install.map(([property_name, property_value]) =>
        result.install[property_name] = property_value
    )
    // console.log(result)
    return result
}

const prettyPrintJSON = (mapData) => {
    const install = {}
    mapData.forEach(([property_name, property_value]) =>
        install[property_name] = property_value
    )
    return JSON.stringify(install, null, 4)
}

// Redux

const strictEqual = (next, prev) => {
    // overwrites the default compare from shallow to strict otherwise our form
    // doesn't get notified of state changes and doesn't re-render for the selector, values in the array
    // are changing but shallow compare doesn't catch them
    const equal = (next === prev)
    return equal
}

const mapStateToProps = (state) => {
    return {
        install: state.config.install || [],
        uaa: state.config.uaa || [],
        error: state.config.error,
        success: state.config.success,
        complexTypeSchema: state.config.complexTypeSchema || [],
        loading: state.config.loading
    }
}

const mapActionsToProps = {
    testTile,
    getConfig,
    editPropertiesFields,
    saveConfig,
    editUAAFields,
    removeUAAField,
    addUAAField,
    addConfigProperty,
    editPropertiesJSON,
    startLoadingConfigContent,
}

export default withRouter(connect(mapStateToProps, mapActionsToProps, undefined, {areStatePropsEqual: strictEqual})(Config))
