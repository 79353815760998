import "./style.css"
import React from "react"
import { render } from "react-dom"
import { Provider } from "react-redux"
import App from "./components/App"
import makeStore from "./makeStore"
import Footer from "./components/isv-ci-common-ui/Footer"

const {store, history} = makeStore()

render(
    <Provider store={store}>
        <App history={history}/>
        <Footer/>
    </Provider>,
    document.getElementById("root")
)

// Service worker is causing tests to pollute, which probably means it's caching pages incorrectly
// registerServiceWorker()
