import React, {Component, Fragment} from "react"
import {connect} from "react-redux"
import {withRouter} from "react-router"
import {getTileContent, receiveTileContent} from "../actions/tileContent"
import {Loader} from "semantic-ui-react"
import {Container, List, Breadcrumb, Message} from "semantic-ui-react"
import {Link} from "react-router-dom"
import Highlight from "react-highlight"

class Child extends Component {
    render() {
        return (
            <List.Item><Link to={this.props.href}>{this.props.name}</Link></List.Item>
        )
    }
}

class Breadcrumbs extends Component {
    render() {
        let path = this.props.path || ""
        let parents = path.split("/")
        let link = "/browse"
        return (
            <Container fluid className="breadcrumb-panel">
                <Breadcrumb size='small'>
                    <Breadcrumb.Section>
                        <Link to={link}>home</Link>
                    </Breadcrumb.Section>
                    {this.props.path && parents.map((parent, p) => {
                        link += "/" + parent
                        return(
                            <Fragment key={p}>
                                <Breadcrumb.Divider>/</Breadcrumb.Divider>
                                <Breadcrumb.Section>
                                    <Link to={link}>{parent}</Link>
                                </Breadcrumb.Section>
                            </Fragment>
                        )
                    })}
                </Breadcrumb>
            </Container>
        )
    }
}

export class TileContent extends Component {
    componentDidMount() {
        let path = this.props.path
        this.props.getTileContent(path)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.path !== this.props.path) {
            this.props.getTileContent(nextProps.path)
        }
    }

    render() {
        let path = this.props.path || ""
        let basename = path.split("/").slice(-1)[0]
        let baseurl = "/browse"
        if (path) baseurl += "/" + path
        let content = this.props.content
        let children = content.children || []
        return (
            <Fragment>
                <Breadcrumbs path={path} />
                { content.loading &&
                    <Loader indeterminate active>
                        Loading {basename}
                    </Loader>
                }
                { !content.loading && content.error &&
                    <Message error>
                        <Message.Header>Error</Message.Header>
                        <p>{content.error}</p>
                    </Message>
                }
                { !content.loading && content.type === "folder" &&
                    <Container>
                        <List relaxed divided>
                            {children.map((child, c) => {
                                child["href"] = baseurl + "/" + child.name
                                return(<Child key={c} {...child} />)
                            })}
                        </List>
                    </Container>
                }
                { !content.loading && content.type === "file" &&
                    <Container style={{fontSize: "small"}}>
                        <Highlight>
                            {content.content}
                        </Highlight>
                    </Container>
                }
            </Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    content: state.tileContent,
    path: ownProps.match.params[0]
})

const mapActionsToProps = {
    receiveTileContent,
    getTileContent
}

export default withRouter(connect(mapStateToProps, mapActionsToProps)(TileContent))
