import React from "react"
import {Button, Container, Icon, Input} from "semantic-ui-react"

const ClearSearchButton = (props) => {
    return (
        <Button icon {...props}>
            <Icon link name='cancel'/>
        </Button>
    )
}

const SearchBar = ({action, value}) => (
    <Container fluid style={{padding: "0em 1em 1em 1em"}}>
        <Input
            style={{width: "100%"}}
            size="small"
            placeholder="Search..."
            action={
                <ClearSearchButton
                    size="small"
                    onClick={() => {
                        action("")
                    }}
                />
            }
            value={value}
            onInput={(e) => {
                action(e.target.value)
            }}
            onKeyUp={e => {
                if (e.keyCode === 27) {
                    action("")
                }
            }}

        />
    </Container>
)

export default SearchBar