import {createAction} from "redux-actions"
import * as HTTPStatus from "http-status-codes"
import {FetchOptions} from "../client-common/actions/common"


export const getConfig = (slug, release) => {
    return dispatch => {
        dispatch(clearConfig())
        dispatch(startLoadingConfigContent())
        return fetch(`/api/tiles/${slug}/releases/${release}/config?include_metadata`, FetchOptions).then(response => {
            if (response.status === HTTPStatus.OK) {
                return response.json()
            }
            return Promise.reject(Error(`HTTP Status Code: ${response.status} ${response.statusText}`))
        }).then(result => {
            dispatch(receiveConfig(result))
        }).catch(error =>{
            dispatch(receiveConfig(error))
        })
    }
}

export const saveConfig = (slug, configData, runTestTile=false) => {
    return dispatch => {
        return fetch(`/api/tiles/${slug}/config`, {
            ...FetchOptions,
            method: "PUT",
            headers: {
                Accept: "application/json",
                "content-type": "application/json"
            },
            body: JSON.stringify(configData)
        }).then(response => {
            if (response.status === HTTPStatus.OK) {
                return response.json()
            }
            return Promise.reject(Error(`HTTP Status Code: ${response.status} ${response.statusText}`))
        }).then(result => {
            dispatch(configSaved(result))

            if (runTestTile) {
                return dispatch(testTile(slug))
            }
        }).catch(error =>{
            dispatch(configSaved(error))
        })
    }
}

export const testTile = (slug) => {
    return dispatch => {
        return fetch(`/api/matrix/${slug}/retest`, FetchOptions).then(response => {
            if (response.status === HTTPStatus.OK) {
                return response.json()
            }
            return Promise.reject(Error(`HTTP Status Code: ${response.status} ${response.statusText}`))
        }).then(result => {
            dispatch(tileTested(result))
        }).catch(error =>{
            dispatch(tileTested(error))
        })
    }
}


export const tileTested = createAction("TILE_TESTED")
export const receiveConfig = createAction("CONFIG_RECEIVED")
export const clearConfig = createAction("CONFIG_CLEARED")
export const editPropertiesFields = createAction("CONFIG_PROPERTIES_FIELDS_EDITED")
export const editUAAFields = createAction("CONFIG_UAA_FIELDS_EDITED")
export const configSaved = createAction("CONFIG_SAVED")
export const removeUAAField = createAction("UAA_FIELD_REMOVED")
export const addUAAField = createAction("UAA_FIELD_ADDED")
export const addConfigProperty = createAction("CONFIG_PROPERTY_ADDED")
export const editPropertiesJSON = createAction("PROPERTY_JSON_EDITED")
export const startLoadingConfigContent = createAction("CONFIG_CONTENT_LOADING")
