import React from "react"
import {Label} from "semantic-ui-react"
import * as FontAwesome from "react-icons/fa"

export const StatusIcon = ({status}) => {
    switch (status) {
        case "shipit":       return <FontAwesome.FaCheckSquare color="green" />
        case "failed":       return <FontAwesome.FaTimesCircle color="#e00000" />
        case "warning":      return <FontAwesome.FaExclamationTriangle color="#e0ad4e" />
        case "untested":     return <FontAwesome.FaRegSquare color="white" />
        case "blocked":      return <FontAwesome.FaMinusCircle color="#eee" />
        case "ready":        return <FontAwesome.FaRegSquare color="#eee" />
        case "in-progress":  return <FontAwesome.FaCog className="icon-spin" color="#e0ad4e" />
        case "found-errors": return <FontAwesome.FaExclamationCircle color="#e00000" />
        case "locked":       return <FontAwesome.FaLock color="#eee" />
        case "help!":        return <FontAwesome.FaFire className="icon-burn" color="#e0ad4e" />
        default:             return ""
    }
}

export const statusText = (status) => {
    switch (status) {
        case "untested":     return "Evaluating"
        case "ready":        return "Queued"
        case "in-progress":  return "Running"
        case "shipit":       return "Complete"
        case "found-errors": return "Found Errors"
        case "warning":      return "Warning"
        case "failed":       return "Failed"
        case "help!":        return "Fatal Error"
        case "blocked":      return "Blocked"
        case "locked":       return "Locked"
        default:             return status
    }
}

export const statusColor = (status) => {
    switch (status) {
        case "untested":     return "yellow"
        case "ready":        return "yellow"
        case "in-progress":  return "yellow"
        case "shipit":       return "green"
        case "found-errors": return "red"
        case "warning":      return "orange"
        case "failed":       return "red"
        case "help!":        return "red"
        case "blocked":      return "red"
        case "locked":       return "grey"
        default:             return "grey"
    }
}

const StatusLabel = (props) => (
    <Label color={statusColor(props.status)} {...props}>{statusText(props.status)}</Label>
)

export default StatusLabel
