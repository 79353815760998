import React, {Component, Fragment} from "react"
import {connect} from "react-redux"
import {withRouter} from "react-router"
import {Link} from "react-router-dom"
import queryString from "query-string"
import {getJobs, receiveJobs} from "../actions/jobs"
import {Tab, Menu} from "semantic-ui-react"
import {StatusIcon} from "../components/StatusLabel"
import CellStatus from "../containers/CellStatus"
import JobSummary from "../containers/JobSummary"

class JobHistory extends Component {

    getHistory(releaseId, targetName) {
        this.props.getJobs(releaseId, targetName)
    }

    generatePanes(jobs, selectedJob) {
        this.panes = []
        this.activeIndex = 0
        const cell = this.props.cell
        var index = 0
        if (cell && !cell.latest_job) {
            this.panes.push({
                menuItem: (<Menu.Item><Link to={"?job="}>{<StatusIcon status={cell.status}/>}</Link></Menu.Item>),
                render: () => (<Tab.Pane><CellStatus cell={cell}/></Tab.Pane>)
            })
            index += 1
        }
        if (jobs) {
            for (var i = 0; i < jobs.length; i++) {
                const job = jobs[i]
                const isMostRecent = (i === 0)
                this.panes.push({
                    menuItem: (<Menu.Item><Link to={"?job=" + job.id}>{<StatusIcon status={job.status}/>}</Link></Menu.Item>),
                    render: () => (<Tab.Pane><JobSummary jobId={job.id} isMostRecent={isMostRecent} isAdmin={this.props.isAdmin}/></Tab.Pane>)
                })
                if (job.id === selectedJob) this.activeIndex = index
                index += 1
            }
        }
    }

    componentDidMount() {
        this.generatePanes([])
        this.getHistory(this.props.releaseId, this.props.targetName)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.releaseId !== this.props.releaseId || nextProps.targetName !== this.props.targetName) {
            this.getHistory(nextProps.releaseId, nextProps.targetName)
        }
        if (nextProps.history !== this.props.history || nextProps.jobId !== this.props.jobId) {
            this.generatePanes(nextProps.history, nextProps.jobId)
        }
    }

    render() {
        const panes = this.panes
        const activeIndex = this.activeIndex
        return (
            <Fragment>
                <Tab panes={panes} activeIndex={activeIndex}/>
            </Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const queryParams = queryString.parse(ownProps.history.location.search)
    const jobId = parseInt(queryParams["job"]) || ownProps.jobId || null
    const releaseId = ownProps.match.params.release
    const targetName = ownProps.match.params.tab
    const history = state.jobs.list
    return ({
        jobId: jobId,
        releaseId: releaseId,
        targetName: targetName,
        history: history
    })
}

const mapActionsToProps = {
    getJobs,
    receiveJobs
}

export default withRouter(connect(mapStateToProps, mapActionsToProps)(JobHistory))
