export const isMemberOf = (user, group) => {
    return (user.groups || []).includes(group)
}

export const makeLink = ({host, path = "/"}) => {
    const currentURL = new window.URL(window.location.href)
    const tld = currentURL.hostname.split(".").slice(1)
    currentURL.hostname = [host, ...tld].join(".")
    currentURL.pathname = path

    return currentURL.href
}

const config = {
    items: [
        {
            id: "self-service",
            title: "Self Service",
            ref: {
                host: "self-service"
            },
        },
        {
            id: "auth",
            title: "My Products and Releases",
            ref: {
                host: "auth"
            }
        },
        {
            id: "docs",
            title: "Documentation",
            ref: {
                host: "auth",
                path: "/documentation"
            }
        },
        {
            id: "dashboard",
            title: "Tile Dashboard",
            ref: {
                host: "tile-dashboard"
            }
        },
    ],
    logout: {
        ref: {
            host: "auth",
            path: "/logout"
        }
    },
    profile: {
        ref: {
            host: "auth",
            path: "/"
        }
    },
    home: {
        ref: {
            host: "self-service"
        }
    }
}

// const itemIsVisible = (user, item) => !(item.membersOnly || false) || isMemberOf(user, item.membersOnly)

const remakeRefs = (item) => {
    return {
        ...item,
        ref: makeLink(item.ref)
    }
}

export const getConfig = (/* user */) => {

    return {
        items: config.items.map(remakeRefs),
        logout: remakeRefs(config.logout),
        profile: remakeRefs(config.profile),
        home: remakeRefs(config.home),
    }
}